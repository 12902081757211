import React from 'react'

import { CommonFieldProps } from '.'

import FormGroup from 'components/common/formGroup'
import DateTimeInput, { DateTimeInputProps } from 'components/common/dateTimeInput'
import { useField } from 'formik'

type DateTimePickerFieldProps = CommonFieldProps & Pick<DateTimeInputProps, 'withoutTimePickerDropdown'>

export const DateTimePickerField: React.FC<DateTimePickerFieldProps> = ({
  label,
  required = false,
  className,
  disabled,
  hideFormGroup,
  withoutTimePickerDropdown,
  ...props
}) => {
  const [{ name, value }, { error }, { setValue }] = useField<string | Date>(props)

  return (
    <FormGroup
      className={className}
      label={label}
      required={required}
      dataContains={name}
      hideFormGroup={hideFormGroup}
    >
      <DateTimeInput
        currentValue={value}
        setValue={setValue}
        disabled={disabled}
        withoutTimePickerDropdown={withoutTimePickerDropdown}
      />
      {error && <div className='text-danger'>{error}</div>}
    </FormGroup>
  )
}
