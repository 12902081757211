import React, { useCallback } from 'react'

import { parse, parseISO, format, getMinutes, getHours, setMinutes, setHours } from 'date-fns'
import styled from 'styled-components'
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from 'utils'

const dateToString = (date: Date, mask: string) => {
  try {
    return date ? format(date, mask) : ''
  } catch (error) {
    console.error(error)
    return ''
  }
}

declare const AUSTRA

const DatetimeRow = styled.div<{ dateFlex?: number; timeFlex?: number }>`
  display: flex;
  flex-flow: row;

  > .datepicker:nth-child(1) {
    flex: ${({ dateFlex }) => dateFlex || 2};
  }

  > .datepicker:nth-child(2) {
    flex: ${({ timeFlex }) => timeFlex || 1};
  }
`

interface DateAndTimeFieldProps {
  className?: string
  dateFieldName?: string
  timeFieldName?: string
  dateValue: string
  timeValue: string
  onDateChange: (date: string) => void
  onTimeChange: (time: string) => void
  dateFlex?: number
  timeFlex?: number
  disabled?: boolean
  withoutTimePickerDropdown?: boolean
}

export const DateAndTimeFields: React.FC<DateAndTimeFieldProps> = ({
  className,
  dateFieldName,
  timeFieldName,
  dateValue,
  timeValue,
  onDateChange,
  onTimeChange,
  dateFlex,
  timeFlex,
  disabled = false,
  withoutTimePickerDropdown = false
}) => {
  return (
    <DatetimeRow className={className} dateFlex={dateFlex} timeFlex={timeFlex}>
      <AUSTRA.RC.Datepicker
        name={dateFieldName ? dateFieldName : 'date'}
        value={dateValue}
        onChange={onDateChange}
        disabled={disabled}
      />
      <AUSTRA.RC.Timepicker
        name={timeFieldName ? timeFieldName : 'time'}
        value={timeValue}
        onChange={onTimeChange}
        disabled={disabled}
        withoutTimePickerDropdown={withoutTimePickerDropdown}
      />
    </DatetimeRow>
  )
}

export interface DateTimeInputProps {
  className?: string
  dateFlex?: number
  timeFlex?: number
  currentValue: Date | string
  disabled?: boolean
  withoutTimePickerDropdown?: boolean
  setValue: (value: Date) => void
}

const DateTimeInput: React.FC<DateTimeInputProps> = ({
  className,
  dateFlex,
  timeFlex,
  currentValue,
  setValue,
  disabled = false,
  withoutTimePickerDropdown = false
}) => {
  if (typeof currentValue === 'string') {
    currentValue = parseISO(currentValue)
  }

  const dateChanged = useCallback(
    (formattedValue: string) => {
      let newValue = parse(formattedValue, DEFAULT_DATE_FORMAT, (currentValue as Date) || new Date())
      if (currentValue) {
        newValue = setHours(newValue, getHours(currentValue as Date))
        newValue = setMinutes(newValue, getMinutes(currentValue as Date))
      }
      setValue(newValue)
    },
    [currentValue, setValue]
  )

  const timeChanged = useCallback(
    (formattedValue: string) => {
      const newValue = parse(formattedValue, DEFAULT_TIME_FORMAT, (currentValue as Date) || new Date())
      setValue(newValue)
    },
    [currentValue, setValue]
  )

  return (
    <DateAndTimeFields
      className={className}
      dateValue={dateToString(currentValue, DEFAULT_DATE_FORMAT)}
      timeValue={dateToString(currentValue, DEFAULT_TIME_FORMAT)}
      onDateChange={dateChanged}
      onTimeChange={timeChanged}
      dateFlex={dateFlex}
      timeFlex={timeFlex}
      disabled={disabled}
      withoutTimePickerDropdown={withoutTimePickerDropdown}
    />
  )
}

export default DateTimeInput
